'use client'
import { logger } from '@/utils/logger'
import { Button, InputError, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { type ReactNode, useState } from 'react'
import { useRouter } from 'next/navigation'
import { AuthError } from '@supabase/supabase-js'
import {
	checkForCrewMember,
	checkForUser,
	createUserFromPhone,
	emailOtpSignin,
} from '@/authActions/loginActions'
import { createEmailOTP } from '@/authActions/loginActions'
import { supabaseBrowserInstance } from '@/lib/supabaseBrowser'

const EmailOTPLogin = () => {
	const router = useRouter()
	const [error, setError] = useState<string | ReactNode>()
	const [submitting, setSubmitting] = useState<boolean>(false)

	const signinForm = useForm<{ email: string; phone: string; password: string }>({
		initialValues: { email: '', phone: '', password: '' },
	})

	const handleEmailOTPSignin = async (values: typeof signinForm.values) => {
		try {
			setSubmitting(true)
			setError(undefined)

			const email = values.email.trim().toLowerCase()
			const cm = await checkForCrewMember({ email })

			if (!cm?.id) {
				throw new AuthError('Crew member not found. Contact your manager to get setup.')
			}
			const user = await checkForUser({ email })

			if (!user) {
				const { ok, error } = await createUserFromPhone(cm.id)
				if (!ok && error) {
					throw new Error(error)
				}
			}
			logger.info('isLogin', user, email)

			if (!cm.profile_id) {
				await supabaseBrowserInstance
					.from('crew_members')
					.update({ profile_id: cm.id })
					.eq('id', cm.id)
			}

			const { status: otpStatus } = await emailOtpSignin(email, !!user)

			if (otpStatus !== 'success') throw new Error('No response from otpSignin function.')

			await createEmailOTP(email)

			router.push(`/auth/otp?email=${encodeURIComponent(email)}`)
		} catch (error) {
			if (error instanceof AuthError) {
				setError(error.message)
			} else if (error instanceof Error) {
				setError(error.message)
			} else {
				logger.error('handleOTPSignin error', error)
				setError('An unknown error occurred')
			}
		} finally {
			setSubmitting(false)
		}
	}
	return (
		<form onSubmit={signinForm.onSubmit(handleEmailOTPSignin)}>
			<TextInput
				{...signinForm.getInputProps('email')}
				label='Email address'
				placeholder='hello@gmail.com'
				size='md'
				type='email'
				autoFocus
			/>
			<InputError mt='sm'>{error}</InputError>
			<Button
				fullWidth
				mt='md'
				size='md'
				type='submit'
				disabled={submitting}
				loading={submitting}
				loaderProps={{ type: 'bars', size: 'xs' }}
			>
				Continue
			</Button>
		</form>
	)
}

export default EmailOTPLogin
