'use client'
import { Box, Center, Group, Stack, Tabs, Text, Title } from '@mantine/core'
import { useState } from 'react'
import CrewHeroLogoV3 from '@/components/CrewHeroLogoV3'
import CrewHeroCard from '@/components/CrewHeroCard'
import { IconSparkles } from '@tabler/icons-react'
import 'react-international-phone/style.css'
import EmailOTPLogin from './EmailOTPLogin'
import PhoneLogin from './PhoneLogin'
import PasswordLogin from './PasswordLogin'

const AuthLoginPage = () => {
	const [loginMethod, setLoginMethod] = useState<'email' | 'phone' | 'password'>('phone')

	return (
		<Stack
			style={{
				background:
					'linear-gradient(180deg, var(--mantine-color-teal-6) 0%, var(--mantine-color-teal-5) 50%, var(--mantine-color-green-4) 100%)',
			}}
			h='100vh'
		>
			<Box m='auto'>
				<CrewHeroCard px='lg' py='lg' maw='320px'>
					<Stack>
						<CrewHeroLogoV3 />
						<Box>
							<Center py='md'>
								<Title order={3}>Let's get you signed in</Title>
							</Center>
						</Box>

						<Tabs
							value={loginMethod}
							onChange={(value) => setLoginMethod(value as 'email' | 'phone' | 'password')}
						>
							<Tabs.List justify='center' grow>
								<Tabs.Tab value='phone'>Phone</Tabs.Tab>
								<Tabs.Tab value='email'>Email</Tabs.Tab>
								<Tabs.Tab value='password'>Password</Tabs.Tab>
							</Tabs.List>

							<Tabs.Panel value='email' pt='md'>
								<EmailOTPLogin />
							</Tabs.Panel>

							<Tabs.Panel value='phone' pt='md'>
								<PhoneLogin />
							</Tabs.Panel>

							<Tabs.Panel value='password' pt='md'>
								<PasswordLogin />
							</Tabs.Panel>
						</Tabs>

						{loginMethod !== 'password' && (
							<CrewHeroCard p='sm' bg={'var(--body-shaded)'}>
								<Group wrap='nowrap' align='center'>
									<Box>
										<IconSparkles stroke={1} />
									</Box>
									<Box>
										<Text size='sm'>
											We'll send a secure link or OTP for password-free sign in.
										</Text>
									</Box>
								</Group>
							</CrewHeroCard>
						)}
					</Stack>
				</CrewHeroCard>
			</Box>
		</Stack>
	)
}

export default AuthLoginPage
