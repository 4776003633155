'use client'
import { Button, InputError, TextInput, Anchor, Box } from '@mantine/core'
import { useForm } from '@mantine/form'
import { passwordLogin } from '@/authActions/loginActions'
import 'react-international-phone/style.css'
import { notifications } from '@mantine/notifications'
import { toastAndReportError } from '@/utils/errorUtils.client'
import { useRouter } from 'next/navigation'
import { type ReactNode, useState } from 'react'

const PasswordLogin = () => {
	const router = useRouter()
	const [error, setError] = useState<string | ReactNode>()
	const [submitting, setSubmitting] = useState<boolean>(false)

	const signinForm = useForm<{ email: string; phone: string; password: string }>({
		initialValues: { email: '', phone: '', password: '' },
	})

	const handlePasswordLogin = async (values: typeof signinForm.values) => {
		try {
			setSubmitting(true)
			setError(undefined)
			const email = values.email.trim().toLowerCase()
			const password = values.password

			const { ok } = await passwordLogin(email, password)
			if (!ok) throw new Error('Invalid email or password')

			notifications.show({
				title: 'Login successful',
				message: 'You have successfully signed in!',
				color: 'green',
			})

			router.push('/manager')
		} catch (error) {
			toastAndReportError(error)
			setSubmitting(false)
		}
	}

	return (
		<form onSubmit={signinForm.onSubmit(handlePasswordLogin)}>
			<TextInput
				{...signinForm.getInputProps('email')}
				label='Email address'
				placeholder='hello@gmail.com'
				size='md'
				type='email'
				autoFocus
			/>
			<TextInput
				{...signinForm.getInputProps('password')}
				label='Password'
				placeholder='Your password'
				size='md'
				type='password'
				mt='md'
			/>
			<Box ta='end'>
				<Anchor mt='xs' size='sm' onClick={() => router.push('/login/forgot-password')}>
					Forgot password?
				</Anchor>
			</Box>
			<InputError mt='sm'>{error}</InputError>
			<Button
				fullWidth
				mt='md'
				size='md'
				type='submit'
				disabled={submitting}
				loading={submitting}
				loaderProps={{ type: 'bars', size: 'xs' }}
			>
				Sign In
			</Button>
		</form>
	)
}

export default PasswordLogin
