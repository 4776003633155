'use client'
import { logger } from '@/utils/logger'
import { Button, InputError, Text, Anchor } from '@mantine/core'
import { useForm } from '@mantine/form'
import { type ReactNode, useState } from 'react'
import { useRouter } from 'next/navigation'
import { AuthError } from '@supabase/supabase-js'
import {
	checkForCrewMember,
	checkForUser,
	createSMSOTP,
	createUserFromPhone,
} from '@/authActions/loginActions'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

const env = process.env.NEXT_PUBLIC_ENV
const initialValues =
	env === 'local'
		? { phone: process.env.NEXT_PUBLIC_LOCAL_PHONE || '', email: '', password: '' }
		: { phone: '', email: '', password: '' }

const PhoneLogin = () => {
	const router = useRouter()
	const [error, setError] = useState<string | ReactNode>()
	const [submitting, setSubmitting] = useState<boolean>(false)

	const signinForm = useForm<{ email: string; phone: string; password: string }>({
		initialValues: initialValues,
	})

	const handleOTPSignin = async (values: typeof signinForm.values) => {
		try {
			setSubmitting(true)
			setError(undefined)

			if (values.phone) {
				const phone = values.phone.trim()
				const cm = await checkForCrewMember({ phone })
				if (!cm?.id) {
					throw new AuthError('Crew member not found. Contact your manager to get setup.')
				}

				const userExists = await checkForUser({ phone })

				if (!userExists) {
					const { ok, error } = await createUserFromPhone(cm.id)
					if (!ok && error) {
						throw new Error(error)
					}
				}

				const result = await createSMSOTP(phone)

				if (result.ok) {
					router.push(`/auth/verify-phone-otp?phone=${encodeURIComponent(phone)}`)
				} else if (result.code === 'otp_signup_not_allowed') {
					setError(
						<Text size='xs' span>
							User for this cell not setup. Please contact{' '}
							<Anchor href='mailto:support@crewhero.app'>support@crewhero.app</Anchor>
						</Text>,
					)
				} else if (result.message) {
					setError(result.message)
				} else {
					throw new Error('An unknown error occurred')
				}
			} else {
				throw new Error('Phone number is required')
			}
		} catch (error) {
			if (error instanceof AuthError) {
				setError(error.message)
			} else if (error instanceof Error) {
				setError(error.message)
			} else {
				logger.error('handleOTPSignin error', error)
				setError('An unknown error occurred')
			}
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<form onSubmit={signinForm.onSubmit(handleOTPSignin)}>
			<PhoneInput
				{...signinForm.getInputProps('phone')}
				defaultCountry='us'
				inputStyle={{ width: '100%', height: '36px' }}
			/>
			<InputError mt='sm'>{error}</InputError>
			<Button
				fullWidth
				mt='md'
				size='md'
				type='submit'
				disabled={submitting}
				loading={submitting}
				loaderProps={{ type: 'bars', size: 'xs' }}
			>
				Continue
			</Button>
		</form>
	)
}

export default PhoneLogin
